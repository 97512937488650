import React, { useEffect } from "react"
import { Box } from "@theme-ui/components"

const DynamicsForm = ({ formId }) => {
  useEffect(() => {
    const scriptLoader = document.createElement("script")
    scriptLoader.id = "scriptLoader"
    scriptLoader.src =
      "https://cxppusa1formui01cdnsa01-endpoint.azureedge.net/eur/FormLoader/FormLoader.bundle.js"
    setTimeout(() => {
      document.body.appendChild(scriptLoader)
      document
        .getElementById("scriptLoader")
        .addEventListener("d365mkt-afterformsubmit", function (event) {
          console.log("success - " + event.detail.successful)
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: "submit",
          })
        })
    }, 100)
  })

  return (
    <Box
      sx={{
        backgroundColor: "white",
        opacity: 0.9,
      }}
    >
      <div
        data-form-id={`${formId}`}
        data-form-api-url="https://public-eur.mkt.dynamics.com/api/v1.0/orgs/f9397812-8358-4918-8b54-06c7d3ce416a/landingpageforms"
        data-cached-form-url={`https://assets-eur.mkt.dynamics.com/f9397812-8358-4918-8b54-06c7d3ce416a/digitalassets/forms/${formId}`}
      ></div>
    </Box>
  )
}

export default DynamicsForm
