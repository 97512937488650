import React from "react"
import { Box, Container, Text } from "@theme-ui/components"
import Form from "./form"
import DynamicsForm from "./dynamicsForm"

const FormEmbed = ({
  title,
  subtitle,
  label,
  dataFormBlockId,
  dataWebsiteId,
  dataHostname,
  alternate,
  divId,
}) => {
  return (
    <>
      {alternate ? null : (
        <Box sx={{ backgroundColor: "secondary" }}>
          <Container variant="sm" sx={{ paddingY: [5, 7] }}>
            {label && (
              <Text
                dir="invalid"
                as="p"
                variant="caption"
                sx={{
                  color: "light",
                  mb: [3, 4],
                  textTransform: "uppercase",
                }}
              >
                {label}
              </Text>
            )}
            <Text
              dir="invalid"
              variant="h2"
              as="h2"
              sx={{
                color: "light",
                mb: [3, 4],
                mt: 0,
                hyphens: ["auto", "auto", "none", "none"],
              }}
            >
              {title}
            </Text>
            {subtitle && (
              <Text
                dir="invalid"
                as="p"
                sx={{
                  color: "light",
                  mb: [3, 4],
                }}
              >
                {subtitle}
              </Text>
            )}
            <DynamicsForm formId={dataFormBlockId} />
          </Container>
        </Box>
      )}
    </>
  )
}

export default FormEmbed
