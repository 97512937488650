import React from "react"
import { Box, Container, Text } from "@theme-ui/components"


const Embed = ({ title, code, fullWidth }) => {
  // console.log(code);
  const Wrapper = fullWidth ? Box : Container
  return (
    <Wrapper mb={5}>
      {title && <Text dir="invalid" mb={3}>{title}</Text>}
      <Box
        sx={{ backgroundColor: "lightBackground", position: "relative" , width: "100%", height: "0", paddingBottom: "56.25%", iframe: { position: "absolute", top: "0", left: "0", width: "100%", height: "100%" } }}
        dangerouslySetInnerHTML={{ __html: code }}
      />
    </Wrapper>
  )
}

export default Embed
